import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.defaults({
  toggleActions: "restart play resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//

gsap.from("#navigation-slide-toggle", {
  x: 100,
});

// const kenmerken = document.querySelectorAll('.ACF-kernwaarden');
function ornaments(elm) {
  elm.forEach(container => {
    const ornaments = container.querySelector('.ornaments');
    const amount = Math.floor(container.offsetWidth / 70);
    for (let index = 0; index < amount; index++) {
      const element = document.createElement('span');
      ornaments.appendChild(element);
    }
    // jump(ornaments);
  });
}

function jump(elm) {
  const items = elm.querySelectorAll('span');

  const tl = gsap.timeline();

  tl.from(items, {
    yPercent: 100,
    opacity: 0,
    ease: "back.out(1.7)",
    stagger: {
      amount: 0.8,
      from: "edges",
      // ease: "power3.out",
    },
  });

  return tl;
}

const kenmerken = document.querySelectorAll('.ACF-kernwaarden-container');
const snelmenu = document.querySelectorAll('.ACF-snelmenu');

ornaments(kenmerken);
ornaments(snelmenu);

//------------------------------------------------------//
// Animation ELEMENT 
//------------------------------------------------------//
kenmerken.forEach(function (container) {

  const item = container.querySelectorAll(".item");

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      // scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=50%", // Can be top, center, bottom 
      end: "100% bottom-=55%", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.from(item, {
    duration: 0.8,
    yPercent: 100,
    ease: "back.out(1.7)",
    opacity: 0,
    stagger: {
      amount: 0.6,
      from: "edges",
      // ease: "power3.out",
    },
  })
    .add(jump(container), '<');

});

snelmenu.forEach(function (container) {

  const item = container.querySelectorAll(".item");

  const timeline = gsap.timeline({
    scrollTrigger: {
      id: "ELEMENT", // Custom label to the marker
      trigger: container, // What element triggers the scroll
      // scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=50%", // Can be top, center, bottom 
      end: "100% bottom-=55%", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.from(item, {
    duration: 0.8,
    yPercent: 100,
    ease: "back.out(1.7)",
    opacity: 0,
    stagger: {
      amount: 0.6,
      from: "edges",
      // ease: "power3.out",
    },
  })
    .add(jump(container), '<');

});
// END Animation ELEMENT -------------------------------------//
document.querySelectorAll('.ACF-split_content').forEach(function (container) {

  const item = container.querySelectorAll(".alloy-image");

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      // scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=50%", // Can be top, center, bottom 
      end: "100% bottom-=55%", // Can be top, center, bottom 
      // pin: true,
    }
  });

  timeline.from(item, {
    duration: 0.8,
    yPercent: 100,
    ease: "back.out(1.7)",
    autoAlpha: 0,
  })
    .add(jump(container), '<');

});

document.querySelectorAll('.ACF-photogrid').forEach(function (container) {

  const item = container.querySelectorAll(".item");

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      // scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=50%", // Can be top, center, bottom 
      end: "100% bottom-=55%", // Can be top, center, bottom d
      // pin: true,
    }
  });

  timeline.from(item, {
    duration: 0.8,

    yPercent: 100,
    ease: "back.out(1.7)",
    autoAlpha: 0,
    stagger: {
      amount: 0.6,
      from: "edges",
      // ease: "power3.out",
    },
  })
    .add(jump(container), '<');

});