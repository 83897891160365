// import { tns } from "tiny-slider";
import { tns } from 'tiny-slider/src/tiny-slider';
// import { gsap } from "gsap";

const slider = document.querySelectorAll('.slider');
// const kenmerken = document.querySelectorAll('.ACF-kernwaarden');

slider.forEach(container => {
  var slider = tns({
    mode: container,
    autoplay: true,
    controls: false,
    navContainer: false,
  });
});
